import {RouteConfig} from 'vue-router';

export const recoverRouter: RouteConfig = {
  path: '/recover',
  component: () => import(/* webpackChunkName: "recover" */ '@/views/recover/RecoverView.vue'),
  children: [
    {
      path: '',
      name: 'recover',
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "recover" */ '@/views/recover/RecoverPasswordView.vue'),
    },
    {
      path: ':token/:id',
      name: 'reset',
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "recover" */ '@/views/recover/ResetPasswordView.vue'),
    },
    {
      path: 'expired',
      name: 'recover-expired',
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "recover" */ '@/views/recover/TokenExpiredView.vue'),
    },
  ],
};
