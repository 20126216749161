import {apiService} from '@/services/api/ApiService';
import {Principal} from '@/store/models/auth/AuthState';

const AuthService = {
  getPrincipal(data?: { email: string, password: string }): Promise<Principal> {
    const config = data ? {auth: {username: data.email, password: data.password}} : {};
    return apiService.get('/auth/info', config);
  },
  refresh(): Promise<Principal> {
    return apiService.post('/auth/refresh');
  },
  logout(): Promise<void> {
    return apiService.post('/logout');
  },
};

export default AuthService;
