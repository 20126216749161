
  import {Component, Vue} from 'vue-property-decorator';
  import {Action} from 'vuex-class';
  import AuthService from '@/services/api/auth/AuthService';

  @Component
  export default class AppComponent extends Vue {

    @Action('clearAuth', {namespace: 'auth'}) clearAuth!: () => Promise<any>;

    private refreshAuthInterval?: number;

    mounted() {
      this.refreshAuthInterval = setInterval(() => {
        if (!(this.$router.currentRoute.meta && this.$router.currentRoute.meta.public)) {
          AuthService.getPrincipal().catch(() => {
            this.clearAuth().then(() => {
              const currentPath = this.$router.currentRoute.path;
              this.$router.replace({name: 'login', query: {redirectUrl: currentPath}});
            });
          });
        }
      }, 5 * 60 * 1000);
    }

    beforeDestroy() {
      if (this.refreshAuthInterval) {
        clearInterval(this.refreshAuthInterval);
      }
    }
  }
