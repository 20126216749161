import axios from 'axios';
import {errorInterceptor} from '@/services/api/interceptors/ErrorInterceptor';
import {authInterceptor} from '@/services/api/interceptors/AuthInterceptor';

const axiosApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    // @ts-ignore
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
});

axiosApiService.interceptors.response.use((response) => response, errorInterceptor);
axiosApiService.interceptors.response.use((response) => response, authInterceptor);

interface RequestConfig {
  auth?: {
    username: string;
    password: string;
  };
  params?: any;
  data?: any;
  headers?: any;
}

const apiService = {
  get<T>(url: string, config?: RequestConfig): Promise<T> {
    return axiosApiService.get<T>(url, config).then((response) => response.data);
  },
  post<T>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    return axiosApiService.post<T>(url, data, config).then((response) => response.data);
  },
  put<T>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    return axiosApiService.put<T>(url, data, config).then((response) => response.data);
  },
  patch<T>(url: string, data?: any, config?: RequestConfig): Promise<T> {
    return axiosApiService.patch<T>(url, data, config).then((response) => response.data);
  },
  delete(url: string, config?: RequestConfig): Promise<void> {
    return axiosApiService.delete(url, config).then(() => {
      return;
    });
  },
};

export {apiService};
