import {Module} from 'vuex';
import {RootState} from '@/store/models/RootState';
import {AuthState, Principal} from '@/store/models/auth/AuthState';
import AuthService from '@/services/api/auth/AuthService';
import {Vue} from 'vue-property-decorator';

export const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  getters: {
    username(state): string {
      return state.principal ? state.principal.firstname : '';
    },
    userId(state): string {
      return state.principal ? state.principal.id : '';
    },
  },
  mutations: {
    setAuth(state: AuthState, data: Principal) {
      Vue.set(state, 'principal', data);
    },
  },
  actions: {
    authorize({commit}, data?: { email: string, password: string }) {
      return AuthService.getPrincipal(data)
        .then((principal) => {
          commit('setAuth', principal);
          return principal;
        }).catch((e) => {
          commit('setAuth', null);
          throw e;
        });
    },
    refresh({commit}) {
      return AuthService.refresh()
        .then((principal) => {
          commit('setAuth', principal);
          return principal;
        }).catch((e) => {
          commit('setAuth', null);
          throw e;
        });
    },
    clearAuth({commit}) {
      return AuthService.logout()
        .then(() => {
          commit('setAuth', null);
          return true;
        });
    },
  },
};
