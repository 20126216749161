import store from '@/store/store';

import {Route} from 'vue-router';
import {AuthRole} from '@/store/models/auth/AuthRole';

export function indexGuard(to: Route, from: Route, next: (arg?: any) => void) {
  if (store.state.auth.principal) {
    switch (store.state.auth.principal.role) {
      case AuthRole.ADMIN: {
        next({name: 'admin'});
        break;
      }
      case AuthRole.AGENT: {
        next({name: 'agent'});
        break;
      }
      case AuthRole.TENANT: {
        next({name: 'tenant'});
        break;
      }
      default: {
        store.dispatch('auth/clearAuth').then(() => {
          next({name: 'login'});
        }).catch(() => {
          next({name: 'login'});
        });
      }
    }
  } else {
    next({name: 'login'});
  }
}
