import Vue from 'vue';
import Router from 'vue-router';

import {authGuard} from '@/router/guards/auth.guards';
import {indexGuard} from '@/router/guards/index.guard';

import {landingRouter} from '@/router/modules/landing.router';
import {recoverRouter} from '@/router/modules/recover.router';

import {userRouter} from '@/router/modules/user.router';
import {adminRouter} from '@/router/modules/admin.router';
import {agentRouter} from '@/router/modules/agent.router';
import {tenantRouter} from '@/router/modules/tenant.router';

import {invitationRouter} from '@/router/modules/invitation.router';
import {wizardRouter} from '@/router/modules/wizard.router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      beforeEnter: indexGuard,
    },
    {
      path: '/privacy',
      name: 'privacy',
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "general" */ '@/views/general/PrivacyView.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "general" */ '@/views/general/TermsView.vue'),
    },
    landingRouter,
    recoverRouter,
    userRouter,
    adminRouter,
    agentRouter,
    tenantRouter,
    ...invitationRouter,
    ...wizardRouter,
    {
      path: '*',
      redirect: '/',
    },
  ],
});
router.beforeEach(authGuard);

export default router;
