import {ErrorBag} from 'vee-validate';

const errorInterceptor = (error: any) => {
  if (error.response.headers['x-parse-errors']) {
    const errors: ErrorBag = new ErrorBag();
    for (const key in error.response.data) {
      if (error.response.data.hasOwnProperty(key)) {
        for (const errorValue of error.response.data[key]) {
          errors.add({field: key, msg: errorValue});
        }
      }
    }
    error.response.data.parsedErrors = errors;
  }
  return Promise.reject(error);
};

export {errorInterceptor};
