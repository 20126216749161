import {RouteConfig} from 'vue-router';

export const wizardRouter: RouteConfig[] = [
  {
    path: '/wizard/finish',
    name: 'wizard.finish',
    component: () => import(/* webpackChunkName: "wizard" */ '@/views/wizard/Final.vue'),
  },
  {
    path: '/wizard/:step?',
    name: 'wizard',
    component: () => import(/* webpackChunkName: "wizard" */ '@/views/wizard/Step.vue'),
  },
];
