import {RouteConfig} from 'vue-router';
import {AuthRole} from '@/store/models/auth/AuthRole';

export const agentRouter: RouteConfig = {
  path: '/agent',
  meta: {
    role: AuthRole.AGENT,
  },
  component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/AgentView.vue'),
  children: [
    {
      path: '/',
      name: 'agent',
      redirect: {
        name: 'agent.properties',
      },
    },
    {
      path: 'properties',
      name: 'agent.properties',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/properties/PropertiesView.vue'),
    },
    {
      path: 'properties/create',
      name: 'agent.properties.create',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/properties/PropertyForm.vue'),
    },
    {
      path: 'properties/:id/edit',
      name: 'agent.properties.edit',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/properties/PropertyForm.vue'),
    },
    {
      path: 'properties/:id',
      name: 'agent.properties.view',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/properties/PropertyView.vue'),
    },
    {
      path: 'tenants',
      name: 'agent.tenants',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/tenants/TenantsView.vue'),
    },
    {
      path: 'tenants/:id',
      name: 'agent.tenants.view',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/tenants/TenantView.vue'),
    },
    {
      path: 'policies/:state?',
      name: 'agent.policies',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/policies/PoliciesView.vue'),
      props: (route) => ({state: (route.params.state || 'created').toUpperCase()}),
    },
    {
      path: 'policy/:id',
      name: 'agent.policies.view',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/policies/PolicyView.vue'),
    },
    {
      path: 'policy/:id/submit',
      name: 'agent.policies.submit',
      component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/policies/PolicyForm.vue'),
    },
  ],
};
