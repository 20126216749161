const endpoint = process.env.VUE_APP_API_URL;

const FileService = {
  getUploadUrl(userId: string): string {
    return `${endpoint}files/${userId}`;
  },
  getFileUrl(path: string, alias?: string): string {
    if (alias) {
      return `${endpoint}files/${path}?alias=${encodeURIComponent(alias)}`;
    } else {
      return `${endpoint}files/${path}`;
    }
  },
};

export default FileService;
