import {RouteConfig} from 'vue-router';
import {AuthRole} from '@/store/models/auth/AuthRole';

export const adminRouter: RouteConfig = {
  path: '/admin',
  meta: {
    role: AuthRole.ADMIN,
  },
  component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminView.vue'),
  children: [
    {
      path: '/',
      name: 'admin',
      redirect: {
        name: 'admin.tickets',
      },
    },
    {
      path: 'tickets',
      name: 'admin.tickets',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/tickets/TicketsView.vue'),
    },
    {
      path: 'agencies',
      name: 'admin.agencies',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/agencies/AgenciesView.vue'),
    },
    {
      path: 'agencies/create',
      name: 'admin.agencies.create',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/agencies/AgencyForm.vue'),
    },
    {
      path: 'agencies/:id',
      name: 'admin.agencies.edit',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/agencies/AgencyForm.vue'),
    },
    {
      path: 'agents/create',
      name: 'admin.agents.create',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/agents/AgentForm.vue'),
    },
    {
      path: 'agents/:id/edit',
      name: 'admin.agents.edit',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/agents/AgentForm.vue'),
    },
    {
      path: 'agents/:id/credentials',
      name: 'admin.agents.credentials',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/credentials/CredentialsForm.vue'),
    },
    {
      path: 'tenants',
      name: 'admin.tenants',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/tenants/TenantsView.vue'),
    },
    {
      path: 'tenants/:id/edit',
      name: 'admin.tenants.edit',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/tenants/TenantForm.vue'),
    },
    {
      path: 'tenants/:id',
      name: 'admin.tenants.view',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/tenants/TenantView.vue'),
    },
    {
      path: 'tenants/:id/draft',
      name: 'admin.tenants.draft',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/tenants/DraftForm.vue'),
    },
    {
      path: 'tenants/:id/credentials',
      name: 'admin.tenants.credentials',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/credentials/CredentialsForm.vue'),
    },
    {
      path: 'tenants/:id/policy',
      name: 'admin.tenants.policy',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/policies/PolicyForm.vue'),
    },
    {
      path: 'profiles/:userId/:type',
      name: 'admin.userprofile.edit',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/profiles/ProfileForm.vue'),
      props: true,
    },
    {
      path: 'references/:userId/income',
      name: 'admin.userreferences.income',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/references/IncomeForm.vue'),
      props: true,
    },
    {
      path: 'references/:userId/identity',
      name: 'admin.userreferences.identity',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/references/IdentityForm.vue'),
      props: true,
    },
    {
      path: 'policies/:state?',
      name: 'admin.policies',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/policies/PoliciesView.vue'),
      props: (route) => ({state: (route.params.state || 'created').toUpperCase()}),
    },
    {
      path: 'policy/:id',
      name: 'admin.policies.view',
      component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/policies/PolicyView.vue'),
    },
  ],
};
