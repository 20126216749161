import store from '@/store/store';

import {Route} from 'vue-router';
import {Principal} from '@/store/models/auth/AuthState';

export function authGuard(to: Route, from: Route, next: (arg?: any) => void) {
  if (store.state.auth.principal) {
    handlePrincipal(to, store.state.auth.principal, next);
  } else {
    store.dispatch('auth/authorize')
      .then((principal: Principal) => {
        handlePrincipal(to, principal, next);
      }).catch(() => {
      if (to.meta && to.meta.public) {
        next();
      } else {
        if (to.path !== '/') {
          next({name: 'login', query: {redirectUrl: to.path}});
        } else {
          next({name: 'login'});
        }
      }
    });
  }
}

function handlePrincipal(to: Route, principal: Principal, next: any) {
  if (to.matched.every((m) => !m.meta.role || m.meta.role === principal.role)) {
    next();
  } else {
    next('/');
  }
}
