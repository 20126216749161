import {RouteConfig} from 'vue-router';
import {AuthRole} from '@/store/models/auth/AuthRole';

export const tenantRouter: RouteConfig = {
  path: '/tenant',
  meta: {
    role: AuthRole.TENANT,
  },
  component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/TenantView.vue'),
  children: [
    {
      path: '/',
      name: 'tenant',
      redirect: {
        name: 'tenant.policies',
      },
    },
    {
      path: 'policies',
      name: 'tenant.policies',
      component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/policies/PoliciesView.vue'),
    },
    {
      path: 'policy/:id',
      name: 'tenant.policies.view',
      component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/policies/PolicyView.vue'),
    },
    {
      path: 'policy/:id/confirm',
      name: 'tenant.policies.confirm',
      component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/policies/PolicyForm.vue'),
    },
  ],
};
