import {RouteConfig} from 'vue-router';

export const userRouter: RouteConfig = {
  path: '/user',
  component: () => import(/* webpackChunkName: "user" */ '@/views/user/UserView.vue'),
  children: [
    {
      path: '/',
      name: 'user',
      redirect: {
        name: 'user.profile',
      },
    },
    {
      path: 'credentials',
      name: 'user.credentials',
      component: () => import(/* webpackChunkName: "user" */ '@/views/user/CredentialsChangeView.vue'),
    },
    {
      path: 'profile',
      name: 'user.profile',
      component: () => import(/* webpackChunkName: "user" */ '@/views/user/UserProfileView.vue'),
    },
  ],
};
