import {RouteConfig} from 'vue-router';

export const landingRouter: RouteConfig = {
  path: '/landing',
  name: 'landing',
  meta: {
    public: true,
  },
  component: () => import(/* webpackChunkName: "landing" */ '@/views/landing/LandingView.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      meta: {
        public: true,
      },
      component: () => import(/* webpackChunkName: "landing" */ '@/views/landing/login/LoginView.vue'),
    },
  ],
};
