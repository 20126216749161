import {RouteConfig} from 'vue-router';

export const invitationRouter: RouteConfig[] = [
  {
    path: '/invitation/:id',
    name: 'invitation',
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "invitation" */ '@/views/invitation/InvitationView.vue'),
  },
  {
    path: '/registration/thanks',
    name: 'thanks.screen',
    component: () => import(/* webpackChunkName: "invitation" */ '@/views/invitation/ThanksView.vue'),
  },
];
