import store from '@/store/store';
import router from '@/router/router';

const skipRequests: RegExp[] = [
  /auth\/.+/,
];

const authInterceptor = (error: any) => {
  if (error.response.status === 401) {
    const url = error.config.url;
    if (!skipRequests.find((path) => path.test(url))) {
      store.dispatch('auth/clearAuth').then(() => {
        const currentPath = router.currentRoute.path;
        router.replace({name: 'login', query: {redirectUrl: currentPath}});
      });
    }
  }
  return Promise.reject(error);
};

export {authInterceptor};
