import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import {AuthModule} from '@/store/modules/auth.store';
import {RootState} from '@/store/models/RootState';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    auth: {},
    application: 'Tenant Guarantor',
  },
  modules: {
    auth: AuthModule,
  },
};

export default new Vuex.Store<RootState>(store);
