import '@/assets/styles/main.scss';
import 'bootstrap';

import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';

import './class-component-hooks';

import VeeValidate from 'vee-validate';
import dictionary from '@/services/validation/validation-dictionary';
import '@/services/validation/rules';

import '@/services/filters';
import VueMoment from 'vue-moment';
import Vue2Filters from 'vue2-filters';

Vue.config.productionTip = false;
Vue.use(VeeValidate, {inject: false, events: 'change|blur', dictionary});
Vue.use(VueMoment);
Vue.use(Vue2Filters);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
