const dictionary = {
  en: {
    messages: {
      required: (field: string) => `${field} could not be empty`,
      email: () => `Incorrect email address`,
      phone_number: () => `Please enter valid phone number without dashes or spaces.`,
      max: (field: string, max: number) => `${field} cannot be longer than ${max} characters`,
      complex_password: (field: string, length: number) => `${field} cannot be less than ${length} characters`,
      confirmed: (field: string) => `${field} does not match`,
      decimal: (field: string) => `${field} is invalid`,
      min_value: (field: string, min: number) => `${field} cannot be less than ${min}`,
      max_value: (field: string, min: number) => `${field} cannot be more than ${min}`,
      included: (field: string) => `${field} must be valid`,
    },
  },
};

export default dictionary;
